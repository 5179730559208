// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:47d6ddd4-308d-4a8f-8c9e-f8345d12d837",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_piPGAh9BK",
    "aws_user_pools_web_client_id": "5fpaf1qtdp1o39kjalm017f4g0",
    "oauth": {}
};


export default awsmobile;
